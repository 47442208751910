.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #e13b3f;

  min-height: 50px;
}
.footer p {
  color: #fff;
  padding: 15px 0;
  line-height: 20px;
  margin: 0 0 0px;
  min-height: 50px;
}
/* p:last-child {
    margin-bottom: 0;
} */
